/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
    
  }
  
  body::-webkit-scrollbar{
    display: none;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: rgba(0,0,0,0.4) !important;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 0.3rem;
    outline: 0;
}
.modal-header .btn-close{
  color:#c5ebec !important;
  background-color: #eeeeee;
}
.modal-header{
  border-bottom: none !important;
}
.modal-body::-webkit-scrollbar{
  display: none;
}
/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
    background: #011254;
    color: #fff;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    z-index: 996;
    transition: all 0.5s;
  }
  #topbar.topbar-scrolled {
    top: -40px;
  }
  #topbar i {
    padding-right: 6px;
    line-height: 0;
  }

  /*--------------------------------------------------------------
# Top Bar 
--------------------------------------------------------------*/


  @media (max-width:1200px){
  #topbar {
 
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  z-index: 996;
  transition: all 0.5s;
}

  }
  @media (max-width:990px){
    #topbar {
   
    height: 40px;
    font-size: 10px;
    font-weight: 300;
    z-index: 996;
    transition: all 0.5s;
  }
  
    }

  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    padding: 20px 0;
    top: 40px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 992px) {
    #header {
      padding: 15px 0;
    }
  }
  #header.header-scrolled {
    top: 0;
  }
  #header .logo {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  #header .logo a {
    color: #555555;
  }
  #header .logo img {
    max-height: 40px;
  }
  
  /** About Styles **/

  .aboutUs p::first-letter{
    color:#18a7d2;
    font-size: 4em;
    text-shadow: 3px 3px 4px #222;
    float: left;
    padding-right: 16px;
    margin: 0 .3rem 0 0 ;
    
    line-height: 1.2;
  }
  /**
  * Appointment Button
  */
  .appointment-btn {
    margin-left: 25px;
    background: #3fbbc0;
    color: #fff;
    border-radius: 4px;
    padding: 8px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
  }
  .appointment-btn:hover {
    background: #65c9cd;
    color: #fff;
  }
  @media (max-width: 768px) {
    .appointment-btn {
      margin: 0 15px 0 0;
      padding: 6px 15px;
    }
  }  



  /*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/

@media (max-width:767px){
  .navbar ul {
    position: absolute;
    background-color: #011254;
    margin: 0;
    left:0;
    width: 100%;
    top:40px;
    padding: 10px;
    display: inline-block;
    list-style: none;
    align-items: center;
    z-index: 999;
    border-radius: 2px solid grey;
    
  }
}




/*----- Gallery -project icon hover ------*/

.proj {
  display: block;
  width: 100%;
  height: 500px;
  margin-bottom: 0;
  background-position: top center !important;
  z-index: 0;
  position: relative; 
  background: #fff;
  
}


  .proj:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
   /* background: #bdb5b5; Enable this for shade overlay on hover */ 
    opacity: 0;
    z-index: 0;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .proj .text span {
    color: rgba(255, 255, 255, 0.9); }
  .proj h2 {
    color: #fff;
    line-height: 1.2;
    font-size: 18px; }
    .proj h2 a {
      color: #fff; }
  .proj .desc {
    z-index: 1; }
  .proj .icon {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 70px;
    height: 70px;
    content: '';
    z-index: 1;
    background: #fdbe34;
    -webkit-transform: translate(50%, 50%);
    -ms-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
    margin-top: -70px;
    opacity: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .proj .icon span {
      color: #fff; }
  .proj:hover:after {
    opacity: .7; }
  .proj:hover .icon {
    opacity: 1; }



  
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 20px rgba(214, 215, 216, 0.5);
  padding: 20px 0 30px 0;
}
.contact .info-box i {
  font-size: 32px;
  color: #3fbbc0;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #c5ebec;
}
.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}
.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.contact .php-email-form {
  box-shadow: 0 0 20px rgba(214, 215, 216, 0.5);
  padding: 30px;
}
.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}
.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
}
.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #3fbbc0;
}
.contact .php-email-form input {
  padding: 10px 15px;
}
.contact .php-email-form textarea {
  padding: 12px 15px;
}
.contact .php-email-form button[type="submit"] {
  background: #3fbbc0;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}
.contact .php-email-form button[type="submit"]:hover {
  background: #65c9cd;
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #eeeeee;
  padding: 0 0 30px 0;
  color: #555555;
  font-size: 14px;
}
#footer .footer-top {
  background: #f6f6f6;
  padding: 60px 0 30px 0;
}
#footer .footer-top .footer-info {
  margin-bottom: 30px;
}
#footer .footer-top .footer-info h3 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}
#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
}
#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #3fbbc0;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
  background: #65c9cd;
  text-decoration: none;
}
#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #3fbbc0;
  font-size: 18px;
  line-height: 1;
}
#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  color: #555555;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
#footer .footer-top .footer-links ul a:hover {
  color: #3fbbc0;
}
#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
}
#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}
#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #3fbbc0;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}
#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: #65c9cd;
}
#footer .copyright {
  text-align: center;
  padding-top: 30px;
}
#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
}
