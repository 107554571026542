

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.navbarnew-brand-logo{
  width: 3rem;
  height: 2rem;
}
.headerText{
  font-family: 'Anton', sans-serif;
  letter-spacing: 3px;
  

}
.nav-pills .nav-link{
  
  margin-left:20px;
  cursor: pointer;
  color: #fff !important;
  font-family: 'Archivo Narrow', sans-serif;
  
 
}
.nav-pills .nav-link:hover{

 
  background-color: #011254 !important;

}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #011254 !important;
}
.card-view {
  box-shadow: 2px 4px 8px 4px rgba(0,0,0,0.2);
  transition: 0.3s;
 padding: 20px;
 height: 450px
}

.card-view:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.checklist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.checklist li {
  padding: 10px;
  position: relative;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: green;
  border-radius: 50%;
}

.checklist li:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-slide{
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #fff;
  background: #3fbbc0;
}

.slide-contain{
    overflow: hidden;
    height: 700px !important;
}
.slide-contain a.previousButton{
  left: 20px;
  }
  .slide-contain a.nextButton{
  right: 20px;
  }
  .slide-contain a.previousButton, .slide-contain a.nextButton{
  font-size: 22px;
      line-height: 0;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all .3s linear;
      z-index: 1;
      color: #333;
      padding: 10px;
      text-decoration: none;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
  }
.dash-board{
  background-color: #3fbbc0;
  list-style: none;
  
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
}
.dash-board-content{
 overflow: auto;
  height: 960px;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
}
.dash-board-content::-webkit-scrollbar{
  display: none;
}
 .navbar .container-fluid .navbarToggleEff{
  
 position: absolute;
 top: 0px;
 right: 10px;
  
}
.navbar-dark .navbar-toggler {
  border-color: #007bff !important;
}
.navbar-dark .navbar-toggler:focus {
  border-color: none !important;
  box-shadow: none !important;
}

@media only screen and (max-width: 1100px) {
  
 
  .navbarnew-brand-logo{
    width: 2rem;
    height: 2rem;
  }
  .navbar-brand h1{
    font-size: 14px;
  }
}
@media only screen and (max-width: 580px) {
  
  .navbar-bg {
    display: none;
  }
  
 
}
@media only screen and (min-width: 580px) {
  .navbar-mobile {
    display: none;
  }
  .navbared-mobile{
    display: none;
  }
 
}